import { graphql, HeadFC, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useMemo, useState } from "react"
import ArrowWhite from "../assets/img/arrow-right.webp"
import StarImage from "../assets/img/star.png"
import ClutchLogo from "../assets/img/cluch.png"
import SEO from "../components/SEO"
import useSiteMetadata from "../hooks/useSiteMetadata"
import useToggleFaq from "../hooks/useToggleFaq"

import Faq from "../components/PPC/ReactDevPpc/Faq"
import Contact from "../components/Contact"
import TestimonialSlider from "../components/DashboardTestimonials/TestimonialSlider"
import PortfolioDetail from "../components/DashboardPortfolio/PortfolioDetail"

const technologiesContent = (activeTabInfo) => {
  switch (activeTabInfo.key) {
    case "frontend development":
      return (
        <>
          <p className="text-base leading-6 text-black mb-8 font-gorditaregular">
            At Techuz, we understand that stunning designs are just the beginning. Transforming them into <span className="font-gorditamedium">interactive, functional interfaces</span> is where the magic happens. Here's how we bridge the gap:
          </p>
          <p className=" text-[1.5rem] leading-6 text-black mb-8 font-blancomedium">
            Turning Design into Reality:
          </p>
          <ul className="text-base leading-6 text-black mb-8 font-gorditaregular">
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              We seamlessly translate designs from <span className="font-gorditamedium">Figma, AdobeXD, and Sketch</span> into <span className="font-gorditamedium">pixel-perfect</span> websites and application UIs using HTML5 and CSS3.
            </li>
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              We use widely used frameworks like Bootstrap and Tailwind CSS for rapid development and maintain consistent styling.
            </li>
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              To add that extra feel and interactivity, we add CSS or JavaScript animations, ensuring a dynamic user experience.
            </li>
          </ul>
          <p className=" text-[1.5rem] leading-6 text-black mb-8 font-blancomedium">
            Creating Dynamic Experiences:
          </p>
          <ul className="text-base leading-6 text-black mb-8 font-gorditaregular">
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              When it comes to dynamic interfaces dealing with user interactions, we rely on the power of JavaScript technologies.
            </li>
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              Our favorite tools, including ReactJS, Angular, and VueJS, allow us to create modular and reusable front-end components.
            </li>
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              These components integrate seamlessly with your HTML5/CSS3 and external APIs, resulting in dynamic, data-driven experiences.
            </li>
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              We are also very familiar with Progressive Web Applications (PWAs) and Single Page Applications (SPAs), enhancing our ability to meet your project needs.
            </li>
          </ul>
          <p className=" text-[1.5rem] leading-6 text-black mb-8 font-blancomedium">
            SEO Optimization:
          </p>
          <p className="text-base leading-6 text-black mb-8 font-gorditaregular">
            We understand how important it is for your website to show up in search engines. That’s why we use server-side rendering using JavaScript technology. This ensures that we create pages that are SEO-friendly and makes it easier for search engines to crawl and index your content.
          </p>
        </>
      )
    case "backend development":
      return (
        <>
          <p className="text-base leading-6 text-black mb-8 font-gorditaregular">
            Imagine a beautiful website or app that looks good but has no real substance – just a pretty face. Now, imagine our brilliant backend development team bringing it to life. They are responsible for transforming user stories into meaningful and easy-to-use formats, making your application not only engaging but fully functional and user-friendly.
          </p>
          <p className=" text-[1.5rem] leading-6 text-black mb-8 font-blancomedium">
            Solid Foundations:
          </p>
          <p className="text-base leading-6 text-black mb-8 font-gorditaregular">
            We carefully evaluate essential factors such as scalability, performance, security, and cost, and then recommend the most appropriate architecture to meet your needs:
          </p>
          <ul className="text-base leading-6 text-black mb-8 font-gorditaregular">
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              <span className="font-gorditamedium">Micro-services:</span> We use a widely accepted methodology to break down your application into independent, scalable modules, providing agility and flexibility.
            </li>
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              <span className="font-gorditamedium">Serverless:</span> We accept the pay-as-you-go model for more efficient and cost-effective resource utilization.
            </li>
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              <span className="font-gorditamedium">Monolithic:</span> For smaller applications we can opt for this simpler architecture when appropriate.
            </li>
          </ul>
          <p className="text-base leading-6 text-black mb-8 font-gorditaregular">
            We build application upon proven languages and frameworks:
          </p>
          <ul className="text-base leading-6 text-black mb-8 font-gorditaregular">
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              <span className="font-gorditamedium">Python (Django):</span> Great for versatile scripting and fast development.
            </li>
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              <span className="font-gorditamedium">Node.js (Express.js):</span> Perfect for real-time features and high-performance applications.
            </li>
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              <span className="font-gorditamedium">.NET (ASP.NET):</span> A robust and secure framework, ideal for enterprise level solutions.
            </li>
          </ul>
          <p className="text-base leading-6 text-black mb-8 font-gorditaregular">
            We look for specialized technologies to meet specific needs
          </p>
          <ul className="text-base leading-6 text-black mb-8 font-gorditaregular">
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              <span className="font-gorditamedium">Socket.io:</span> Create engaging real-time chat features to keep users engaged.
            </li>
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              <span className="font-gorditamedium">Fireplace:</span> Send attention-grabbing notifications that encourage action.
            </li>
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              <span className="font-gorditamedium">Python for AI:</span> Combining machine learning and artificial intelligence to increase productivity and make smarter decisions.
            </li>
          </ul>
          <p className=" text-[1.5rem] leading-6 text-black mb-8 font-blancomedium">
            Database Expertise
          </p>
          <p className="text-base leading-6 text-black mb-8 font-gorditaregular">
            We excel in managing both structured and unstructured data, including:
          </p>
          <ul className="text-base leading-6 text-black mb-8 font-gorditaregular">
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              <span className="font-gorditamedium">SQL databases (MySQL, PostgreSQL):</span> Securely store structured data to support required application functionality.
            </li>
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              <span className="font-gorditamedium">NoSQL databases (MongoDB, Redis):</span> Handle unstructured data for tasks such as search and storage.
            </li>
          </ul>
          <p className="text-base leading-6 text-black mb-8 font-gorditaregular">
            In complex projects, we strategically mix these databases, such as using NoSQL for chat functionality and SQL for user profile. This approach ensures efficiency and flexibility according to specific project requirements.
          </p>
        </>
      )
    case "Web API development & Integration":
      return (
        <>
          <p className="text-base leading-6 text-black mb-8 font-gorditaregular">
            We specialize in crafting custom APIs that power the user experiences across various devices – web, mobile, desktops, tablets, and more.
          </p>
          <p className=" text-[1.5rem] leading-6 text-black mb-8 font-blancomedium">
            Custom API Development:
          </p>
          <ul className="text-base leading-6 text-black mb-8 font-gorditaregular">
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              We write custom APIs that match the unique logic of your application and seamlessly connect the application database to web or mobile frontends. By Using RESTful principles and GraphQL, we ensure smooth and efficient data exchange.
            </li>
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              Many modern applications also rely on external services such as payment gateways, SMS providers, OTP generators, or complex systems such as booking engines. Our expertise lies in perfectly integrating these services into your application.
            </li>
          </ul>
          <p className=" text-[1.5rem] leading-6 text-black mb-8 font-blancomedium">
            Security and Performance Optimization:
          </p>
          <ul className="text-base leading-6 text-black mb-8 font-gorditaregular">
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              We prioritize the security of your data by incorporating strong authentication, authorization and encryption policies. For example, we can restrict API access to a specific IP address to increase security.
            </li>
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              Our APIs are designed with speed and scalability in mind, assuring optimal performance even in times of high demand. We carefully monitor the timing of queries to efficiently retrieve data from the database.
            </li>
          </ul>
          <p className=" text-[1.5rem] leading-6 text-black mb-8 font-blancomedium">
            Comprehensive Documentation:
          </p>
          <ul className="text-base leading-6 text-black mb-8 font-gorditaregular">
            <li className=" mb-[0.625rem] relative before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#FF3D2E] before:absolute before:left-0 before:top-[0.5rem] pl-[1rem]">
              We rely on widely used tools like Swagger and Postman to create detailed API documentation for each project.
            </li>
          </ul>
        </>
      )
    case "cloud development":
      return (
        <>
          <p className="text-base leading-6 text-black mb-8 font-gorditaregular">
            Cloud computing is changing the IT infrastructure and is gaining popularity due to its many benefits. From cost to flexibility, transparency, and ease of use, the benefits are many. At Techuz, we specialize in the process of deploying or porting your web application to the cloud, ensuring high performance, efficiency and effectiveness. Our expertise spans a variety of cloud computing applications, including cloud navigation, configuration, and outsourcing to leading enterprise providers such as AWS, Google Cloud, Microsoft Azure, and Heroku.
          </p>
          <p className="text-base leading-6 text-black mb-8 font-gorditaregular">
            At Techuz, we simplify infrastructure provisioning, deployment, and management through DevOps practices. Our DevOps team is skilled in continuous integration/continuous deployment (CI/CD) pipelines, automation tools, Infrastructure as Code (IaC), and cloud platforms. With these capabilities, they optimize development processes, minimize development time, and ensure the delivery of top-notch web applications.
          </p>
        </>
      )
    case "android":
      return (
        <>
          <p className="text-base leading-6 text-black mb-8 font-gorditaregular">
            Our seasoned Android Developers are coding gladiators! We develop anything and everything thrown at us into a fully functional app. If you have an idea, we’ll accomplish it for you. It's that simple! Our experience, high coding standards and best practices make us a world class Android development company. We adopt latest Google trends and stay up to date with new technologies such as Kotlin, Dagger 2, RXJava and Android Architecture Components to open a new dimension of possibilities and user experience. With creative design, amazing features and functionality, we develop world-class apps that become the hot favorite in Google Play Store.
          </p>
        </>
      )
    case "i0S":
      return (
        <>
          <p className="text-base leading-6 text-black mb-8 font-gorditaregular">
            We are the apex iOS app development company developing highly secure, high-performing, better optimizing, crash-proof and elegant iOS apps. Our developers have typed hundreds and thousands of lines of quality code that have turned into marvelous iOS applications. You get an edge over your competitors with our services as your iPhone, iPad and Apple Watch apps are powered by the latest technology and tools. And not to forget the engaging app design that creates exceptional experience for your users.
          </p>
        </>
      )
    case "react native":
      return (
        <>
          <p className="text-base leading-6 text-black mb-8 font-gorditaregular">
            React Native is gaining much popularity for developing native Android and iOS apps. We have built high performing and seamless mobile apps for Android and iOS platforms. Our React Native developers are JavaScript Ninjas. That’s the reason the code we write turns into majestic apps. You not only get a flawless and fluid app but using the vast React Native Component library we also make sure that your app is deployed rapidly. With the combination of our dexterity, latest technology and tools such as Redux, Thunk Middleware and Redux DevTools your app provides the ultimate user experience and performance.
          </p>
        </>
      )
    case "ionic":
      return (
        <>
          <p className="text-base leading-6 text-black mb-8 font-gorditaregular">
            If you are looking forward to penetrating into multiple OS markets by developing just one app then a hybrid app is what you need. We develop fully functional and feature-rich hybrid mobile apps that run on cross-platform viz Android, iOS and Windows Phone yet provide native like performance. Our Ionic developers at Techuz are experts in creating unparalleled cross-platform apps with graceful design and powerful performance. Using the mobile SDK, UI library and several other functions of this framework and synthesizing it with our Angular, HTML and CSS expertise we craft apps that reigns the multiple OS markets.
          </p>
        </>
      )
  }
}

const ServicesPage = ({
  data: {
    servicesJson: post,
    allServicesPortfolioJson: { portfolio }
  },
}) => {
  const [activeTab, setActiveTab] = useState(post.technologies[0].key)
  const activeTabInfo = useMemo(() => {
    return post.technologies?.find(e => e.key === activeTab)
  }, [activeTab, setActiveTab])

  const { readMore, getToggleMeClass } = useToggleFaq()

  return (
    <>
      <section className="pt-[7.625rem] pb-[4.375rem] md:pt-36 lg:pt-40 lg:pb-28 bg-[#F8F7F3]">
        <div className="container">
          <div className="max-w-[50rem] mx-auto text-center">
            <h1 className="text-black text-[2.25rem] md:text-[2.75rem] lg:text-[3.25rem] leading-[3.25rem] lg:leading-[3.981rem] pb-[1.688rem] md:pb-9 lg:pb-10 font-blancomedium">
              {post.title_1.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
              <span className="inline text-orangecol">.</span>
            </h1>
            <p className="text-base lg:text-[1.125rem] lg:leading-[1.875rem]">
              {post.description_1.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
            </p>
            <Link
              to="/contact-us/"
              className="inline-flex gap-5 items-center bg-white-200 justify-start inline-white text-black border border-white shadow-1xl text-base rounded-3xl pl-6 py-1 pr-1 font-gorditamedium mt-10 mb-[1.875rem] md:mt-[3.063rem] md:mb-10 group ease-in duration-300"
            >
              Share your requirements
              <span className="inline-flex w-10 h-10 bg-primary justify-center items-center rounded-full overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent relative group-hover:border-primary">
                <img
                  src={ArrowWhite}
                  alt="ArrowRight"
                  className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                />
                <img
                  src={ArrowWhite}
                  alt="ArrowRight"
                  className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                />
              </span>
            </Link>


            <div className="text-center text-xs leading-[0.938rem] mt-[1.625rem] flex items-center gap-2 flex-wrap align-middle justify-center">
              <span className="text-sm md:text-base">4.9/5</span>
              <span className="flex flex-nowrap items-center gap-[0.188rem]">
                <img
                  src={StarImage}
                  alt="Star icon"
                  className="max-w-[0.75rem]"
                />
                <img
                  src={StarImage}
                  alt="Star icon"
                  className="max-w-[0.75rem]"
                />
                <img
                  src={StarImage}
                  alt="Star icon"
                  className="max-w-[0.75rem]"
                />
                <img
                  src={StarImage}
                  alt="Star icon"
                  className="max-w-[0.75rem]"
                />
                <img
                  src={StarImage}
                  alt="Star icon"
                  className="max-w-[0.75rem]"
                />
              </span>
              <span>based on <em className="font-gorditamedium not-italic">41</em> verified reviews</span>
              <img
                src={ClutchLogo}
                alt="Clutch"
                className="max-w-[5.25rem]"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="md:pt-28 pt-16">
        <div className="container">
          <div className="text-center">
            <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] pb-5 font-blancomedium md:my-0 md:mx-auto relative max-[767px]:inline-block md:max-w-[48.438rem] mx-auto ">
              {post.title_2.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
              <span className="inline text-orangecol">.</span>
            </h2>
            <p className="text-base leading-[1.875rem] md:max-w-[57.188rem] mx-auto">
              {post.description_2}
            </p>
          </div>
          <ul className="flex flex-wrap justify-between xl:mx-[-2.813rem] pt-12">
            <li className="md:max-w-[50%] w-full md:pl-[2.813rem] md:pr-[2.813rem] pb-10">
              <h4 className="text-2xl font-blancomedium pb-3">Proven process</h4>
              <p>We make sure to thoroughly understand what our users need by using a detailed <span className="italic font-gorditamedium">discovery process</span> that includes user stories and wireframes. This helps us to start building every app with a strong foundation, reducing the chances of missing any important requirements.</p>
            </li>
            <li className="md:max-w-[50%] w-full md:pl-[2.813rem] md:pr-[2.813rem] pb-10">
              <h4 className="text-2xl font-blancomedium pb-3">Modern technology stack</h4>
              <p>We use the newest technologies to make <span className="italic font-gorditamedium">sure the apps</span> we create for you are secure and can grow alongside your business. By utilizing cloud infrastructure, we ensure flexibility and the ability to handle whatever challenges come your way.</p>
            </li>
            <li className="md:max-w-[50%] w-full md:pl-[2.813rem] md:pr-[2.813rem] pb-10">
              <h4 className="text-2xl font-blancomedium pb-3">Deep expertise</h4>
              <p>We have built a wide range of products from <span className="italic font-gorditamedium">SaaS platforms</span> and marketplaces to <span className="italic font-gorditamedium">eCommerce solutions</span> and <span className="italic font-gorditamedium">enterprise portals</span>. Just tell us what you are envisioning, and we will use our expertise to make it a reality, and tailor our approach to your specific needs.</p>
            </li>
            <li className="md:max-w-[50%] w-full md:pl-[2.813rem] md:pr-[2.813rem] pb-10">
              <h4 className="text-2xl font-blancomedium pb-3">Track record of success</h4>
              <p>Over the last 11 years, we have completed over <span className="italic font-gorditamedium">250 successful projects</span> for over <span className="italic font-gorditamedium">150 satisfied clients</span> located in <span className="italic font-gorditamedium">12 countries</span>. Our dedication is based not only on meeting expectations but on going above and beyond.</p>
            </li>
          </ul>
        </div>
      </section>

      {post.awards_images && (
        <section className="result-sec lg:pt-[4.375rem] pt-10">
          <div className="container">
            <div className="flex flex-wrap justify-between items-center">
              <div className="w-full">
                <div className="max-w-full m-auto text-center">
                  <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium mb-[1.875rem] md:mb-10 md:mx-auto relative max-[767px]:inline-block  ">
                    {post.title_3.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                    <span className="inline text-orangecol">.</span>
                  </h2>
                </div>
              </div>

              <ul className="grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 justify-between">
                {post.awards_images.map(({ image, alt }, idx) => {
                  image = getImage(image)
                  return (
                    <li
                      key={`${idx}-awards_image`}
                      className={`${idx == Math.floor(post.awards_images.length / 2) ? "bg-[url('../assets/img/mask.webp')] bg-no-repeat bg-cover border-none" : ""} w-full flex flex-col items-center justify-center rounded-[1.25rem] border border-cream p-[1.875rem] text-center`}
                    >
                      <GatsbyImage image={image} alt={alt} />
                    </li>
                  )
                })}
              </ul>

            </div>
          </div>
        </section>
      )}
      <section className="py-10 md:py-[1.875rem] lg:py-[3.438rem]">
        <div className="container">
          <div className="text-center md:w-10/12 mx-auto mb-10">
            <h3 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium lg:max-w-[59.375rem] mx-auto mb-5">
              {post.title_4}
              <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
            </h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[1.875rem] lg:gap-y-10 md:gap-x-10 lg:gap-x-[4.5rem] mt-10 md:mt-[3.125rem]">
            {post.development_types?.map(({ key, logo, type, description, css }, idx) => {
              return (
                <div
                  key={`${key}-${idx}`}
                  className={` ${idx === post.development_types?.length - 1 ? "" : ""
                    }`}
                >
                  <figure className="h-11 w-11 mb-5 md:mb-[1.875rem]">
                    <img className="w-full h-full" src={logo.publicURL} alt={type} loading="lazy" />
                  </figure>
                  <h3 className={`inline-block italic py-2 pb-[0.375rem] px-[1.125rem] md:px-6 rounded-[6.25rem] ${css} text-xs md:text-sm lg:text-base mb-2 md:mb-5 font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]`}>
                    {type}
                  </h3>
                  <div className="">
                    <p id={`${key}-${idx}`}
                      className="text-[0.938rem] md:text-base leading-[1.625rem]">
                      {description.length > 300 ? (
                        <>
                          <span dangerouslySetInnerHTML={{ __html: readMore[key] ? description : description.slice(0, 300) }}></span>
                          <span
                            onClick={() => getToggleMeClass(key, true)}
                            className="underline transition-all cursor-pointer italic text-[#0B57D0] capitalize hover:text-primary"
                          >
                            {readMore[key] ? " show less" : "...read more"}
                          </span>
                        </>
                      ) : (
                        <span dangerouslySetInnerHTML={{ __html: description }}></span>
                      )}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>

      <div className="bg-gradient-to-t from-[#ffffff] to-[#F7F8F9]">
        {post.questionaries && (
          <section className="py-10 md:py-[1.875rem] lg:py-[3.438rem]">
            <div className="container">
              <div className="text-center md:max-w-[57rem] w-full mx-auto mb-5 md:mb-[3.75rem]">
                <h3 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium lg:max-w-[59.375rem] mx-auto mb-5">
                  {post.title_7.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                  <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
                </h3>
                <p>We understand that every project is unique, and so is our approach to growth. At Techuz, we adapt our process to your specific needs, whether you have a well-defined vision or just the beginning stages of an idea.</p>
              </div>
              <div className="lg:px-20 px-0">
                {post.questionaries.map(
                  ({ key, question, answer, image, alt }, idx) => {
                    image = getImage(image)
                    return (
                      <div
                        key={`${key}-${idx}`}
                        className={`${idx === post.questionaries.length - 1
                          ? "pt-10 lg:pt-[4.375rem]"
                          : "relative py-10 lg:py-[4.375rem] after:content-[''] after:block after:absolute after:bottom-0 after:left-0 after:right-0 after:w-full after:h-[0.063rem] after:bg-gradient-to-r from-[#C9C9C900] from-10% via-[#C9C9C9] via-30% to-[#C9C9C900] to-90%"
                          }`}
                      >
                        <h3 className="md:text-3xl text-xl mb-2.5 font-blancomedium">
                          <span className="mr-5">{idx + 1}.</span> {question}
                        </h3>
                        {answer.map((text, idx) => (
                          <p
                            key={`${text}-${idx}`}
                            className="text-base leading-normal text-black pb-5"
                          >
                            {text}
                          </p>
                        ))}

                        {image && (
                          <GatsbyImage
                            image={image}
                            alt={alt}
                            className="w-full mt-5"
                          />
                        )}
                      </div>
                    )
                  }
                )}
              </div>
            </div>
          </section>
        )}
        <section className="py-10 lg:py-[3.438rem] testimonial-sec md:py-[1.875rem]">
          <div className="container">
            <div className="w-full text-center lg:pb-10 pb-9">
              <div className="title-label text-center"><span className="font-gorditamedium inline-block bg-cream italic py-[0.563rem] pb-[0.375rem] px-[1.5rem] rounded-[6.25rem] mb-[0.625rem] text-sm leading-[1.813rem]">Testimonials</span></div>
              <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium lg:max-w-[47rem] my-0 mx-auto">
                Here’s what  <span className="italic text-primary">our clients say</span> about us
                <span className="inline text-orangecol">.</span>
              </h2>
              <p className="text-base lg:text-[1.125rem] lg:leading-[1.875rem]">They’ve praised and highly rated our work on various review platforms.</p>
            </div>

            <TestimonialSlider />
          </div>
        </section >
        <section className="py-10 md:py-[1.875rem] lg:py-[3.438rem]">
          <div className="container">
            <div className="text-center w-10/12 mx-auto mb-10">
              <h3 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium lg:max-w-[59.375rem] mx-auto mb-5">
                {post.title_6}
                <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
              </h3>
            </div>
            <div className="lg:px-10 px-0">
              <div className="grid md:grid-cols-[200px_auto] lg:grid-cols-[338px_auto] items-start">
                <ul className="relative max-md:flex flex-wrap max-md:gap-4 max-md:justify-center  max-md:pl-0 pl-5 max-md:before:hidden before:contnt-[''] before:absolute before:left-0 before:top-5 before:w-[0.063rem] before:h-[calc(100%-66px)] before:bg-[#D9D9D9]">
                  {post.technologies.map(({ key, type }, idx) => {
                    return (
                      <li
                        onClick={() => setActiveTab(key)}
                        key={`${key}-${idx}`}
                        className={`cursor-pointer font-gorditamedium before:top-5 relative max-md:before:hidden before:content-[''] before:absolute before:h-1 before:w-1 before:rounded-[50%] before:bg-[#D9D9D9] before:-left-[1.375rem] max-md:mb-0 mb-[1.875rem]  ${idx !== 0 ? "" : ""
                          } ${activeTab === key
                            ? "before:bg-[#000]"
                            : ""
                          }`}
                      >
                        <span
                          className={`inline-block px-6 pt-[0.625rem] pb-[0.5rem] text-base font-gorditamedium text-black rounded-[6.25rem] hover:bg-[#E8E3FF] duration-300 ease-in-out ${activeTab !== key
                            ? "bg-[#F7F8F9]"
                            : "bg-[#E8E3FF] underline italic"
                            }`}
                        >
                          {type}
                        </span>
                      </li>
                    )
                  })}
                </ul>
                <div className="lg:pl-16 md:pl-12 pl-0 mt-10 md:mt-0">
                  {technologiesContent(activeTabInfo)}
                  <ul className="flex flex-wrap technologies-sec">
                    {activeTabInfo?.images?.map(
                      ({ image, name, slug }, idx) => {
                        if (!(image.extension === "svg")) image = getImage(image)
                        if (slug)
                          return (
                            <li
                              key={`${activeTabInfo?.description}-${idx}`}
                              className="flex flex-col items-center cursor-pointer py-6 px-8 bg-[#F7F8F9] border border-1 border-[#F7F8F9] rounded-[1.25rem] duration-300 ease-in-out hover:border-[#000]"
                            >
                              <Link to={`${slug}/`} className="inline-block text-center">
                                {image.extension === "svg" ? (
                                  <img
                                    loading="lazy"
                                    src={image.publicURL}
                                    alt={name}
                                    className="h-10 w-10 inline-block"
                                  />
                                ) : (
                                  <GatsbyImage image={image} alt={name} />
                                )}
                                <p className="text-sm font-gorditamedium text-black pt-3 text-center">
                                  {name}
                                </p>
                              </Link>
                            </li>
                          )
                        return (
                          <li
                            key={`${activeTabInfo?.description}-${idx}`}
                            className="flex flex-col items-center justify-center cursor-pointer py-6 px-8 bg-[#F7F8F9] border border-1 border-[#F7F8F9] rounded-[1.25rem] duration-300 ease-in-out hover:border-[#000] hover:bg-transparent"
                          >
                            {image.extension === "svg" ? (
                              <img
                                loading="lazy"
                                src={image.publicURL}
                                alt={name}
                                className="h-10 w-10"
                              />
                            ) : (
                              <GatsbyImage image={image} alt={name} />
                            )}
                            <p className="text-sm font-gorditamedium text-black pt-3">
                              {name}
                            </p>
                          </li>
                        )
                      }
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="bg-gradient-to-t from-[#ffffff] to-[#F7F8F9]">
        <section className="py-10 md:py-12 lg:py-14 overflow-hidden">
          <div className="container">
            <div className="title-sec text-center md:max-w-[60.938rem] m-auto">
              <h4 className="inline-block py-[0.438rem] px-[1.188rem] rounded-[6.25rem] bg-[#E8E3FF] text-xs md:text-sm lg:text-base mb-[0.875rem] md:mb-5 lg:mb-[1.875rem] font-gorditaregular leading-[1.125rem] md:leading-[1.813rem]">No matter your starting point, we're your <em className="font-gorditamedium">partner in success</em>.</h4>
              <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium">
                {post.title_9.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                <span className="inline text-orangecol">.</span>
              </h2>
            </div>

            <ul className="grid grid-cols-1 lg:grid-cols-4 gap-x-[1.688rem] gap-y-[1.875rem] mt-10 relative z-10 before:content-['*'] before:h-[0.063rem] before:absolute before:w-[500%] before:bg-black before:top-8 before:left-[50%] before:-translate-x-1/2 before:-z-10 max-lg:before:w-[0.063rem] max-lg:before:max-lg:h-full max-lg:before:top-0 max-lg:before:left-6 max-lg:before:translate-x-0">
              <li className="relative pl-16 lg:pl-0">
                <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#F7F8F9] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">1</span>
                <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">Design standards</h4>
                <ul className="text-[0.938rem] md:text-base leading-[1.625rem]">
                  <li className="relative pl-4 before:content-[''] before:absolute before:h-[0.375rem] before:w-[0.375rem] before:bg-[#FF3D2E] before:rounded-md before:left-0 before:top-2 mb-[0.625rem]">User-centered approach</li>
                  <li className="relative pl-4 before:content-[''] before:absolute before:h-[0.375rem] before:w-[0.375rem] before:bg-[#FF3D2E] before:rounded-md before:left-0 before:top-2 mb-[0.625rem]">Brand consistency</li>
                  <li className="relative pl-4 before:content-[''] before:absolute before:h-[0.375rem] before:w-[0.375rem] before:bg-[#FF3D2E] before:rounded-md before:left-0 before:top-2 mb-[0.625rem]">Responsive design</li>
                  <li className="relative pl-4 before:content-[''] before:absolute before:h-[0.375rem] before:w-[0.375rem] before:bg-[#FF3D2E] before:rounded-md before:left-0 before:top-2">Modern aesthetics</li>
                </ul>
              </li>
              <li className="relative pl-16 lg:pl-0">
                <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#F7F8F9] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">2</span>
                <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">Coding standards</h4>
                <ul className="text-[0.938rem] md:text-base leading-[1.625rem]">
                  <li className="relative pl-4 before:content-[''] before:absolute before:h-[0.375rem] before:w-[0.375rem] before:bg-[#FF3D2E] before:rounded-md before:left-0 before:top-2 mb-[0.625rem]">Clean and maintainable code</li>
                  <li className="relative pl-4 before:content-[''] before:absolute before:h-[0.375rem] before:w-[0.375rem] before:bg-[#FF3D2E] before:rounded-md before:left-0 before:top-2 mb-[0.625rem]">Industry best practices</li>
                  <li className="relative pl-4 before:content-[''] before:absolute before:h-[0.375rem] before:w-[0.375rem] before:bg-[#FF3D2E] before:rounded-md before:left-0 before:top-2 mb-[0.625rem]">Version control</li>
                  <li className="relative pl-4 before:content-[''] before:absolute before:h-[0.375rem] before:w-[0.375rem] before:bg-[#FF3D2E] before:rounded-md before:left-0 before:top-2">Automated testing</li>
                </ul>
              </li>
              <li className="relative pl-16 lg:pl-0">
                <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#F7F8F9] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">3</span>
                <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">Security practices</h4>
                <ul className="text-[0.938rem] md:text-base leading-[1.625rem]">
                  <li className="relative pl-4 before:content-[''] before:absolute before:h-[0.375rem] before:w-[0.375rem] before:bg-[#FF3D2E] before:rounded-md before:left-0 before:top-2 mb-[0.625rem]">Proactive approach</li>
                  <li className="relative pl-4 before:content-[''] before:absolute before:h-[0.375rem] before:w-[0.375rem] before:bg-[#FF3D2E] before:rounded-md before:left-0 before:top-2 mb-[0.625rem]">Vulnerability scanning & patching</li>
                  <li className="relative pl-4 before:content-[''] before:absolute before:h-[0.375rem] before:w-[0.375rem] before:bg-[#FF3D2E] before:rounded-md before:left-0 before:top-2 mb-[0.625rem]">Secure infrastructure</li>
                  <li className="relative pl-4 before:content-[''] before:absolute before:h-[0.375rem] before:w-[0.375rem] before:bg-[#FF3D2E] before:rounded-md before:left-0 before:top-2">Data encryption</li>
                </ul>
              </li>
              <li className="relative pl-16 lg:pl-0 max-lg:bg-[#F7F8F9]">
                <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#F7F8F9] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">4</span>
                <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">Performance optimization</h4>
                <ul className="text-[0.938rem] md:text-base leading-[1.625rem]">
                  <li className="relative pl-4 before:content-[''] before:absolute before:h-[0.375rem] before:w-[0.375rem] before:bg-[#FF3D2E] before:rounded-md before:left-0 before:top-2 mb-[0.625rem]">Fast loading times</li>
                  <li className="relative pl-4 before:content-[''] before:absolute before:h-[0.375rem] before:w-[0.375rem] before:bg-[#FF3D2E] before:rounded-md before:left-0 before:top-2 mb-[0.625rem]">Code optimization</li>
                  <li className="relative pl-4 before:content-[''] before:absolute before:h-[0.375rem] before:w-[0.375rem] before:bg-[#FF3D2E] before:rounded-md before:left-0 before:top-2 mb-[0.625rem]">Load balancing and scalability</li>
                  <li className="relative pl-4 before:content-[''] before:absolute before:h-[0.375rem] before:w-[0.375rem] before:bg-[#FF3D2E] before:rounded-md before:left-0 before:top-2">Regular monitoring and performance analysis</li>
                </ul>
              </li>
            </ul>
          </div>
        </section>

        <section className="support-sec pt-6 md:pt-8 py-14 pb-0 relative">
          <div className="container">
            <div className="w-full title-sec pb-12">
              <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium text-center">
                Projects <em className="text-primary">powered by</em> our development team
                <span className="inline-block text-[#FF9100]">.</span>
              </h2>
            </div>

            {
              post.portfolios_slug.map((slug, idx) => {
                const project = portfolio.find((project) => slug === project.slug)
                project.backgroundStyles = post.portfolios_background_style[idx]
                return (<PortfolioDetail key={`portfolio-${idx}`} {...project} />)
              })
            }
          </div>
        </section>

        <section className="result-sec lg:py-[6.875rem] md:pt-20 py-10">
          <div className="container">
            <div className="flex flex-wrap justify-between align-middle">
              <div className="my-3 lg:max-w-[27.5rem] w-full">
                <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium text-left">
                  {post.best_company_title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                </h2>
              </div>
              <div className="w-full lg:max-w-[calc(100%-27.5rem)] lg:pl-20">
                <div className="pb-[1.875rem] md:pb-10">
                  <h3 className="text-2xl leading-[2.125rem] mb-[0.625rem] font-blancomedium">Quality-driven approach</h3>
                  <p className="text-base leading-[1.625rem]">We prioritize excellence in everything, from designing beautiful UI designs to coding, ensuring your application stands out from the rest.</p>
                </div>
                <div className="pb-[1.875rem] md:pb-10">
                  <h3 className="text-2xl leading-[2.125rem] mb-[0.625rem] font-blancomedium">Skill and Dedication</h3>
                  <p className="text-base leading-[1.625rem]">Our team is composed of top talent who are dedicated to the success of your business. We promote a culture of excellence that directly benefits you.</p>
                </div>
                <div className="pb-[1.875rem] md:pb-10">
                  <h3 className="text-2xl leading-[2.125rem] mb-[0.625rem] font-blancomedium">Production efficiencies</h3>
                  <p className="text-base leading-[1.625rem]">We have used years of experience to hone our systems and equipment for maximum efficiency. This ensures we deliver the best without sacrificing speed or quality.</p>
                </div>
                <div className="">
                  <h3 className="text-2xl leading-[2.125rem] mb-[0.625rem] font-blancomedium">Cost-effective solution</h3>
                  <p className="text-base leading-[1.625rem]">Using our global presence, we offer competitive pricing without compromising our efficiency. With us, you get an exceptional return on your investment.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {post.faqs.length > 0 && <Faq faqData={post.faqs} />}

      <Contact />
    </>
  )
}

export const query = graphql`
  query ($slug: String!, $portfolios_slug: [String]) {
    servicesJson(slug: { eq: $slug }) {
      seo_title
      seo_description
      slug
      title_1
      description_1
      title_2
      description_2
      title_3
      portfolios_slug
      awards_images {
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 30)
          }
        }
        alt
      }
      title_4
      title_5
      title_6
      title_7
      title_8
      title_9
      best_company_title
      development_types {
        key
        logo {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
          publicURL
        }
        type
        description
        css
      }
      technologies {
        key
        type
        images {
          name
          slug
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
            extension
            publicURL
          }
          alt
        }
      }
      questionaries {
        key
        question
        answer
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        alt
      }
      fieldss {
        key
        field
        description
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        alt
      }
      industries
      industries_images {
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        description
        alt
      }
      hideFAQ
      portfolios_background_style
      faqs {
        key
        question
        answer
        listing
        conclusion
      }
    }
    allServicesPortfolioJson(filter: { slug: { in: $portfolios_slug } }) {
      portfolio: nodes {
        title
        slug
        subTitle
        description
        clutchLink
        country
        budget
        industry
        featuredImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
          publicURL
        }
        featuredImageAlt
      }
    }
  }
`
export default ServicesPage

export const Head: HeadFC = ({ location, data }) => {
  const { siteUrl } = useSiteMetadata()
  const { seo_title, seo_description } = data["servicesJson"]
  return (
    <SEO
      title={seo_title}
      canonical={`${siteUrl}${location.pathname}`}
      description={seo_description}
    />
  )
}
